import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Reducers/rootReducer';
import logger from 'redux-logger'

import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
const config = {
  // Only 'TOGGLE_TODO' will be triggered in other tabs
  initiateWithState: true
};
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(initialState={}) {
  const list=[thunk,logger, createStateSyncMiddleware({
    blacklist: ["persist/PERSIST", "persist/REHYDRATE","SET_LEVEL_VALUE","VERIFY_OTP_SUCCESS"],
  })];

  
  // if (dev) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 
    let store = createStore(
      persistedReducer,
      initialState,
      applyMiddleware(...list),
     
    );

  return store;
}

export const store =configureStore();


