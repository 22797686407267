// Action types

export const SEND_PHONE_VERIFICATION_REQUEST = 'SEND_PHONE_VERIFICATION_REQUEST';
export const SEND_PHONE_VERIFICATION_SUCCESS = 'SEND_PHONE_VERIFICATION_SUCCESS';
export const SEND_PHONE_VERIFICATION_FAILED = 'SEND_PHONE_VERIFICATION_FAILED';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const SEND_RESET_REQUEST = 'SEND_RESET_REQUEST';
export const SEND_RESET_SUCCESS = 'SEND_RESET_SUCCESS';
export const SEND_RESET_FAILED = 'SEND_RESET_FAILED';

export const SEND_NET_POSITIONS_REQUEST = 'SEND_NET_POSITIONS_REQUEST';
export const SEND_NET_POSITIONS_SUCCESS = 'SEND_NET_POSITIONS_SUCCESS';
export const SEND_NET_POSITIONS_FAILED = 'SEND_NET_POSITIONS_FAILED';


export const SEND_INITIAL_TABLE_REQUEST = 'SEND_INITIAL_TABLE_REQUEST';
export const SEND_INITIAL_TABLE_SUCCESS = 'SEND_INITIAL_TABLE_SUCCESS';
export const SEND_INITIAL_TABLE_FAILED = 'SEND_INITIAL_TABLE_FAILED';

export const SET_LEVEL_VALUE='SET_LEVEL_VALUE';
export const SET_SPREAD_ID='SET_SPREAD_ID';
