import {
    SEND_PHONE_VERIFICATION_REQUEST,
    SEND_PHONE_VERIFICATION_SUCCESS,
    SEND_PHONE_VERIFICATION_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    SEND_RESET_REQUEST,
SEND_RESET_SUCCESS,
SEND_RESET_FAILED,
SEND_NET_POSITIONS_REQUEST,
SEND_NET_POSITIONS_SUCCESS,
SEND_NET_POSITIONS_FAILED,
SEND_INITIAL_TABLE_REQUEST,
SEND_INITIAL_TABLE_SUCCESS,
SEND_INITIAL_TABLE_FAILED,
SET_LEVEL_VALUE,
SET_SPREAD_ID
} from "../Constants/actionTypes.constants";
// import { getApiErrorMessage } from '../utils/index';
// import { getFromLocalStorage } from "../utils";
export const initialStore = {
    // kycPhone: getFromLocalStorage('phoneNumber') ? getFromLocalStorage('phoneNumber') : '',
    kycPhone: '',
    tokenExpiry: false,
    spread_id:null,
    user_id: null,
    resetMessage:'',
    resetLoading:false,
    netPositions:null,
    is_admin:null,
    spreads:{}
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case SEND_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SEND_PHONE_VERIFICATION_SUCCESS:
            return {
                ...state,
                kycPhone: action.phone,
                phoneOtp:'',
                loading: false,
                error: false,
            };
        case SEND_PHONE_VERIFICATION_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                phoneOtp:'',
                userDetails: {},
                error: false,
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                phoneOtp:action.phoneOtp,
                userDetails: action.data.user_details,
                tokenExpiry: false,
                spread_id:action.data.spreads[0],
                spread_array:action.data.spreads,
                user_id:action.data.user_id,
                is_admin:action.data.is_admin,
                error: false,
            };
        case VERIFY_OTP_FAILED:
            return {
                ...state,
                phoneOtp: '',
                userDetails: {},
                error: true,
            };
            case SEND_RESET_REQUEST:
                return {
                    ...state,
                  resetLoading:true,
                    error: false,
                };
            case SEND_RESET_SUCCESS:
                return {
                    ...state,
                    resetLoading:false,
                    resetMessage:"Reset Succesful",
                    resetReason:action?.data?.message,
                    error: false,
                };
            case SEND_RESET_FAILED:
                return {
                    ...state,
                    resetLoading:false,
                    resetMessage:"Reset Failed",
                    resetReason:action?.error?.message,
                    error: true,
                };
                case SEND_NET_POSITIONS_REQUEST:
                    return {
                        ...state,
                    
                        error: false,
                    };
                case SEND_NET_POSITIONS_SUCCESS:
                    return {
                      ...state,
                      netPositions:{...state.netPositions,
                        [action.data.spread_id]:action?.data?.net_position,
                    },
                       
                      avgSpread:{...state.avgSpread,
                        [action.data.spread_id]:action?.data?.avg_spread,
                    } ,
                      spreads:
                      {...state.spreads,
                       [action.data.spread_id] :
                        action.allSpreads === 1 ?
                         action?.data?.spreads:
                           [...action?.data?.spreads,...state?.spreads[action.data.spread_id]] 
                        },
                        //   : [...action?.data?.spreads,...state?.spreads[action.data.spread_id] ],
                      error: false,
                    };
                case SEND_NET_POSITIONS_FAILED:
                    return {
                        ...state,
                     
                        error: true,
                    };
                    case SEND_INITIAL_TABLE_REQUEST:
                        return {
                            ...state,
                        
                            error: false,
                        };
                    case SEND_INITIAL_TABLE_SUCCESS:
                        return {
                            ...state,
                           initialTableData:action.data,
                            error: false,
                        };
                    case SEND_INITIAL_TABLE_FAILED:
                        return {
                            ...state,
                         
                            error: true,
                        };
                        case SET_LEVEL_VALUE:
                            return{
                                ...state,
                                level:action.data
                            };
                            case SET_SPREAD_ID:
                            return{
                                ...state,
                                spread_id:action.data
                            }
        default:
            return state
    }
}