import React, { useState, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import Singleton from "./socket";


import ProtectedRoute from './ProtectedRoute';


const Login = lazy(() => import('./Components/Login/Login' /* webpackChunkName: "login" */));
const Home = lazy(() => import('./Components/Home/Home' /* webpackChunkName: "home" */));


function Routes  (
    ){

  return (
    <>
     
            <header >
                <Switch>
{/* <Layout> */}
                    <Route path="/" strict exact component={Login} />

                    <ProtectedRoute path="/home" exact component={Home} />
                    <Route path="/home/:spreadId"  exact  component={Home}  />
{/* </Layout> */}

                </Switch>
            </header>
        
    </>
);

  }
const mapStateToProps = (state:any) => ({
    
  
  });
  
  const mapDispatchToProps = (dispatch:any) => ({
 
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Routes);