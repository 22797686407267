/* eslint-disable */
import React, { useState, useRef, useEffect,Suspense, lazy } from "react";
import "./App.css";
import { Provider } from 'react-redux';
import { store } from './store';
import { Router } from 'react-router-dom';
import { history } from './History';
import AppRoutes from './routes';
import "./fonts/MyFontsWebfontsKit.css";
import AppLoader from './Components/appLoader/appLoader';
import {  initStateWithPrevTab } from 'redux-state-sync';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
initStateWithPrevTab(store);

const persistor = persistStore(store);
function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
         <Suspense fallback={<AppLoader />}>
      <Router history={history}>
      <div >
            <AppRoutes  />
          </div>
      </Router>
      </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
